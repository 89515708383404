<template>
	<el-row class="container">
		<!--顶部条-->
		<el-col :span="24" class="header">
			<el-col :span="6" class="logo" :class="collapsed?'logo-collapse-width':'logo-width'">
				<span @click="goHmoe">运营管理系统</span>
			</el-col>
			<el-col :span="8">
				<div class="tools" @click.prevent="collapse">
					<i class="el-icon-s-fold" title="收起/展开左侧导航"></i>
				</div>
				<span class="fz16 white">欢迎登陆{{sysName}}管理系统！</span>
			</el-col>
			<!--顶部右侧用户信息模块  -->
			<el-col :span="10" class="userinfo">
				<el-dropdown trigger="hover">
					<span class="el-dropdown-link userinfo-inner">{{sysTrueName}}<img :src="this.sysUserAvatar" /></span>
					<el-dropdown-menu slot="dropdown" class="user-dropdown">
						<li class="dropdown-menu-item"><img :src="this.sysUserAvatar" /></li>
						<li class="dropdown-menu-item">{{sysTrueName}}</li>
						<li class="dropdown-menu-item">{{sysUserName}}</li>
						<li class="dropdown-menu-item user-operation">
							<ul>
								<li @click="changePassword">修改密码</li>
							</ul>
						</li>
						<li class="dropdown-menu-item">
							<el-button size="large" @click.native="logout">退出登录</el-button>
						</li>
					</el-dropdown-menu>
				</el-dropdown>
			</el-col>
		</el-col>

		<el-col :span="24" class="main">
			<aside :class="collapsed?'menu-collapsed':'menu-expanded'">
				<!--导航菜单-->
				<el-menu class="el-menu-vertical-demo" @open="handleopen" @close="handleclose" @select="handleselect" unique-opened router v-if="!collapsed">		 
					<template v-for="(item,index) in $router.options.routes">
						<template v-if="!item.hidden">
						<el-submenu :index="index+''" v-if="!item.leaf">
							<template slot="title"><i :class="item.iconCls"></i>{{item.name}}</template>
							<el-menu-item v-for="child in item.children" :index="child.path" :key="child.path" v-if="!child.hidden">
								{{child.name}}
							</el-menu-item>
						</el-submenu>
						<el-menu-item v-if="item.leaf&&item.children.length>0" :index="item.children[0].path"><i :class="item.iconCls"></i>{{item.children[0].name}}</el-menu-item>
						</template>
					</template>
				</el-menu>
				<!--导航菜单-折叠后-->
				<ul class="el-menu el-menu-vertical-demo collapsed" v-else ref="menuCollapsed">
					<li v-for="(item,index) in $router.options.routes" v-if="!item.hidden" class="el-submenu item">
						<template v-if="!item.leaf">
							<div class="el-submenu__title s1" @mouseover="showMenu(index,true)" @mouseout="showMenu(index,false)"><i :class="item.iconCls"></i></div>
							<ul class="el-menu submenu" :class="'submenu-hook-'+index" @mouseover="showMenu(index,true)" @mouseout="showMenu(index,false)">
								<li v-for="child in item.children" v-if="!child.hidden" :key="child.path" class="el-menu-item s2" :class="$route.path==child.path?'is-active':''"
								 @click="$router.push(child.path)">{{child.name}}</li>
							</ul>
						</template>
						<template v-else>
							<li class="el-submenu">
								<div class="el-submenu__title el-menu-item s3" style="" :class="$route.path==item.children[0].path?'is-active':''"
								 @click="$router.push(item.children[0].path)"><i :class="item.iconCls"></i></div>
							</li>
						</template>
					</li>
				</ul>
			</aside>
			<section class="content-container">
				<div class="grid-content bg-purple-light">
					<el-col :span="24" class="breadcrumb-container" :class="{none:$route.name=='统计报表'}">
						<strong class="title">{{$route.name}}</strong>
						<el-breadcrumb separator="/" class="breadcrumb-inner">
							<el-breadcrumb-item v-for="item in $route.matched" :key="item.path">
								{{ item.name }}
							</el-breadcrumb-item>
						</el-breadcrumb>
					</el-col>
					<el-col :span="24" class="content-wrapper">
						<transition name="fade" mode="out-in">
							<!-- <keep-alive> -->
								<!-- @getMsgCout="getMsgCout" -->
								<router-view></router-view>
							<!-- </keep-alive> -->
						</transition>
					</el-col>					
				</div>
			</section>
		</el-col>
	</el-row>
</template>
<script>
	const avatar = 'http://image.zgxyzx.net/user.png';
	import {
		logout
	} from '../api/api';
	export default {
		data() {
			return {
				sysName: '运营管理系统',
				collapsed:false,
				sysTrueName: '',
				sysUserAvatar: '',
				sysUserID: '',
				sysUserName: '',
				sysUserGroupName: '',
				// msgConut: '',
			}
		},
		methods: {
			//获取富文本编辑器传来的内容值
			// getMsgCout(...data) {
			// 	this.msgConut = data.toString();
			// },
			handleopen() {},
			handleclose() {},
			handleselect: function(a, b) {},
			//未读消息转跳
			messaga: function() {
				this.$router.push({
					name: '消息管理'
				})
			},
			//点击微信、修改密码转跳系统的个人设置页面
			changePassword: function(){
				this.$router.push({ name: '修改密码'})
			},
			// 返回首页
			goHmoe: function(){
				console.log("goHmoe")
				this.$router.push({ name: '首页'})
			},
			//退出登录
			logout: function() {
				var _this = this;
				this.$confirm('确认退出吗?', '提示', {
					//type: 'warning'
				}).then(() => {
					localStorage.clear();
					_this.$router.push({name: 'login'})
				}).catch(() => {

				});
			},
			//折叠导航栏
			collapse: function() {
				this.collapsed = !this.collapsed;
			},
			showMenu(i, status) {
				this.$refs.menuCollapsed.getElementsByClassName('submenu-hook-' + i)[0].style.display = status ? 'block' : 'none';
			},
		},
		mounted() {
            console.log('$router',this.$router)
			//刷新页面
			let isone = localStorage.getItem('isOne')
			if (!isone) {
				localStorage.setItem('isOne', true)
				location.reload();
			}
			//获取登陆用户信息
			var user = localStorage.getItem('user');
			if (user) {
				user = JSON.parse(user);
				this.sysTrueName = user.true_name;
				this.sysUserAvatar = user.avatar || avatar;
				this.sysUserID = user.id;
				this.sysUserGroupName = user.group_name || '管理员角色组';
				this.sysUserName = user.user_name;
			}
		}
	}
</script>

<style lang="scss">
	@import "../styles/home.scss";
	/*左侧导航底部色 */
	.container .main .menu-expanded {
		background: none;
	}	
	.el-table .cell, .el-table th div, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell{ padding: 0 }
</style>
